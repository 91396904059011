<template>
  <div id="printArea">
    <h4 class="text-uppercase">Consumer Details</h4>

    <CRow>
      <CCol sm="4" class="pr-0">
        <CCard class="px-4">
          <CRow>
            <CCol class="avatar-container">
              <img
                  class="mb-2"
                  name="cil-user"
                  :src="getImgUrl('user.png')"
                  alt="User Logo"
              />
            </CCol>

            <CCol class="mt-3">
              <h4>
                {{ customer.first_name }}
              </h4>
              <h4><strong>Account No #{{ customer.account_number }}</strong></h4>
            </CCol>
          </CRow>

          <ul class="customer-detail-list">
            <li>
              <p>Husband/Father</p>
              <p>
                : {{ customer.guardian }}
              </p>
            </li>
            <li>
              <p>Contact No</p>
              <p>: {{ customer.mobile_number }}</p>
            </li>
            <li>
              <p>Email</p>
              <p>: {{ customer.email }}</p>
            </li>
            <li>
              <p>Category</p>
              <p>: {{ customer.category }}</p>
            </li>
            <li>
              <p>Connection Fee</p>
              <p>
                : {{ customer.connection_fee }}
              </p>
            </li>
            <li>
              <p>Ward Number</p>
              <p>: {{ customer.ward_number }}</p>
            </li>
            <li>
              <p>Area</p>
              <p>: {{ customer.area && customer.area.name +'('+customer.area.area_code+')' }}</p>
            </li>
            <li>
              <p>Address 1</p>
              <p>: {{ customer.address }}</p>
            </li>
            <li>
              <p>Address 2</p>
              <p>: {{ customer.address2 }}</p>
            </li>
            <li>
              <p>Memo No</p>
              <p>
                : {{ customer.memo_no }}
              </p>
            </li>
            <li>
              <p>NID No</p>
              <p>
                : {{ customer.national_id_no }}
              </p>
            </li>
            <li>
              <p>TIN No</p>
              <p>
                : {{ customer.tin_no }}
              </p>
            </li>
            <li>
              <p>Property Type</p>
              <p>
                : {{ customer.property_type }}
              </p>
            </li>

            <li>
              <p>Class</p>
              <p> : {{ customer.class }} </p>
            </li>
            <li>
              <p>Connection date</p>
              <p> : {{ customer.connection_date | dateFormat }} </p>
            </li>
            <li>
              <p>Non Domestic</p>
              <p> : {{ customer.non_domestic }} </p>
            </li>
            <li>
              <p>Avg Consumption</p>
              <p> : {{ customer.avg_consumption }} </p>
            </li>
            <li>
              <p>Mi Code</p>
              <p>: {{ customer.meter_inspector && customer.meter_inspector.mi_code }}</p>
            </li>
            <li>
              <p>Family</p>
              <p>: {{ customer.family }}</p>
            </li>
            <li>
              <p>Freedom Fighter</p>
              <p>: {{ customer.freedom_fighter ? 'Yes' : 'No' }}</p>
            </li>
            <li>
              <p>Exemption VAT</p>
              <p>: {{ customer.exemption_vat ? 'Yes' : 'No' }}</p>
            </li>
            <li>
              <p>Exemption Surch</p>
              <p>: {{ customer.exemption_surch ? 'Yes' : 'No' }}</p>
            </li>
            <li>
              <p>Ministry Code</p>
              <p>: {{ customer.ministry_code }}</p>
            </li>
            <li>
              <p>Account Group</p>
              <p>: {{ customer.account_group }}</p>
            </li>

          </ul>
        </CCard>
      </CCol>
      <CCol sm="8">
        <CCard>
          <CRow>
            <CCol class="text-right d-print-none">
              <CButton @click="print()" color="info" shape="pill"
              >Print
              </CButton
              >
            </CCol>
            <div class="text-right d-print-none">
              <router-link class="btn btn-primary btn-pill" :to="{name:'EditConsumer', params:{id:customer.id}}">Edit
              </router-link>
            </div>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                  <tr class="text-center">
                    <th scope="col">Meter No</th>
                    <th scope="col">Size of Conn.</th>
                    <th scope="col">Meter Status</th>
                    <th scope="col">Meter Type</th>
                    <th scope="col">Install date</th>
                    <!--                      <th scope="col">
                                            Current <br />
                                            Consumption
                                          </th>
                                          <th scope="col">
                                            Last <br />
                                            Reading At
                                          </th>-->
                    <!-- <th scope="col" class="d-print-none">Actions</th> -->
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center" v-if="customer.meter">
                    <td scope="row">
                      <router-link :to="'/meters/' + customer.meter.id">
                        {{ customer.meter.meter_number }}
                      </router-link
                      >
                    </td>
                    <td scope="row">
                      {{ customer.meter.connection_size }}
                    </td>
                    <td scope="row">
                      {{ customer.meter.meter_status }}
                    </td>
                    <td scope="row">
                      {{ customer.meter.meter_type }}
                    </td>
                    <td scope="row">
                      {{ customer.meter.installing_date | dateFormat }}
                    </td>
                    <!--                      <td scope="row">
                                            <span
                                              class="badge"
                                              :class="
                                                customer.meter.status === 'METER_ASSIGNED'
                                                  ? 'badge-warning'
                                                  : customer.meter.status === 'METER_INSTALLED'
                                                  ? 'badge-primary'
                                                  : 'badge-success'
                                              "
                                            >
                                              {{ customer.meter.status }}</span
                                            >
                                          </td>
                                          <td scope="row">
                                            {{ customer.meter.current_consumption_unit }}
                                          </td>
                                          <td scope="row">
                                            {{
                                              customer.meter.reading_date_time
                                                ? dateFormat(customer.meter.reading_date_time)
                                                : "N/A"
                                            }}
                                          </td>-->

                    <!-- <td scope="row" class="d-print-none">
                      <CButton
                        @click="showMeterStatusModal(customer.meter)"
                        color="primary"
                        shape="pill"
                        >Update Status</CButton
                      >
                    </td> -->
                  </tr>
                  </tbody>
                  <!-- <tbody >
                    <tr>
                      <td colspan="9">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody> -->
                </table>
              </div>
            </CCol>
          </CRow>
        </CCard>
        <CCard v-if="customer.meter && customer.meter.id">
          <CRow>
            <CCol sm="12">
              <h4>Consumption Chart</h4>
              <ConsumptionBarChartMeterWise
                  :meterId="customer.meter.id"
                  :printPage="printPage"
              />
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>

    <CModal
        class="hide-footer custom-close-button"
        title="Update Meter Status"
        color="primary"
        :show.sync="updateStatusModal"
    >
      <UpdateMeterStatus
          :meter="meter"
          @closeUpdateMeterStatusModal="closeUpdateMeterStatusModal"
      ></UpdateMeterStatus>
    </CModal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UpdateMeterStatus from "./partials/UpdateMeterStatus";

const ConsumptionBarChartMeterWise = () =>
    import(
        /* webpackChunkName: "ConsumptionBarChartMeterWise" */ "../../views/charts/ConsumptionBarChartMeterWise"
        );
export default {
  name: "ConsumerView",
  components: {
    UpdateMeterStatus,
    ConsumptionBarChartMeterWise,
  },

  data: () => {
    return {
      // customer: { first_name: "", last_name: "" },
      meterStatus: [],
      meter: {},
      updateStatusModal: false,
      consumerId: Number,
      meterId: Number,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      deletingState: false,
      loadingState: false,
      role: {},
      serverErrors: [],
      showDismissibleAlert: false,
      printPage: false,
      customer: {},
      // isLoading: false,
      breadcrumbs: [
        {text: "Home", path: "/dashboard", isActive: false},
        {
          text: "Consumers",
          path: `/consumers`,
          isActive: false,
        },
        {text: "View", path: "", isActive: true},
      ],
    };
  },

  methods: {
    showMeterStatusModal(meter) {
      this.meter = meter;
      this.updateStatusModal = true;
    },

    closeUpdateMeterStatusModal(meter) {
      this.meterStatus = _.map(this.meters, (item) => {
        if (item.id === meter.id) {
          item = meter;
        }
        return item;
      });
      this.updateStatusModal = false;
    },
    getImgUrl(pic) {
      return require("../../assets/images/" + pic);
    },

    print() {
      this.printPage = true;
      setTimeout(() => {
        this.printSetup();
      }, 50);
    },

    printSetup() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printArea").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
          "",
          "",
          "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    <title>Consumer Detail - ${this.customer.account_number}</title>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.addEventListener("load", () => {
        setTimeout(() => {
          WinPrint.print();
          WinPrint.close();
          this.printPage = false;
        }, 200);
      });
    },
  },
  // computed: { ...mapGetters("Consumer", ["customer"]) },
  mounted() {
    this.$store.dispatch("Dashboard/resetBarChartData");
    this.consumerId = this.$route.params.id;
    this.$store
        .dispatch("Consumer/consumerAccountDetails", {
          id: this.consumerId, query: `?isShow=${true}`
        })
        .then((res) => {
          this.customer = res.data.data;
        });
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.custom-close-button >>> button {
  color: aliceblue;
}

.hide-footer >>> footer {
  display: none;
}

.card {
  border: none;
  padding: 30px 20px;
}

tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}

.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}

.badge {
  padding: 5px 7px;
}

.custom-width {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.avatar-container {
  max-width: 100px;
}

.customer-detail-list {
  margin-top: 5px;
  list-style-type: none;
  padding: 0;

  li {
    width: 100%;
    float: left;

    p {
      float: left;
    }

    p {
      &:first-of-type {
        width: 35%;
        font-weight: 600;
      }

      &:last-of-type {
        width: 65%;
      }
    }
  }
}

div {
  .row {
    .card {
      p {
        color: #000;
      }

      .row {
        p {
          color: #5e76e7;
        }
      }
    }
  }

  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }

  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }

    tbody {
      font-size: 13px;

      tr {
        color: #000;

        td {
          padding: 0.75rem 0 0.75rem 0.75rem;

          button {
            font-size: 13px;
          }
        }

        td:nth-child(1) {
          color: #5e76e7;
        }

        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>
